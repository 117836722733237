
import { Options, Vue } from 'vue-class-component';
import { ShopClient, CartClient } from '@/services/Services';
import { selfUrl } from '@/config';
import ImageGalleryModal from '@/components/imageGalleryModal.vue';
import * as OM from '@/model';
import ShareButton from '@/components/shareButton.vue';
import { CommunityClient } from '@/services/Services';
import { LocalizationServices } from '@/services/LocalizationServices';

@Options({
    components: {
        ShareButton
    }
})
export default class ShopItemDetail extends Vue {

    loaded: boolean = false;
    communityIdentifier: string = "";
    shopItemIdentifier: string = "";
    shopItemSlug: string = ""; 

    model: OM.CategoryShopItemVM = new OM.CategoryShopItemVM();
    amountNumber: number = 1;
    
    selectedItem: OM.ShopItemVariationAvailability = new OM.ShopItemVariationAvailability();
    distinctVariations = {}; 

    price: number = 0;
    identifier: string = "";

    shareUrl: string = "";
    errorMessage: string = "";

    async created() {
        this.selectedItem.variation.variations = {};

        await CommunityClient.getBySlug(this.$route.params.slug.toString())
        .then(x =>{
            this.communityIdentifier = x.identifier;
            
            ShopClient.getShopItemDetailBySlug(x.identifier, this.$route.params.shopItemSlug.toString())
            .then(x => {
                this.shopItemIdentifier = x.identifier;
                this.model = x;
                var url = this.$route.path;
                this.shareUrl = selfUrl + url + "?f=" + this.$route.params.shopItemSlug.toString();
                this.setDistinctVariation();
            })
        })

    }

    setVariation(key: string, variation: string) {
        this.selectedItem.variation.variations[key] = variation;
        var variationObj = this.model.shopItemVariations.filter( x => {
            if(x.variation.variations[key] == variation)
                return x;
        })

        var filteredKeys = Object.keys(this.distinctVariations).filter( x => {
            return x != key;
        });

        filteredKeys.forEach( x => {
            var values = [];
            variationObj.forEach( y => {
                values.push(y.variation.variations[x])
            })

            if(values.indexOf(this.selectedItem.variation.variations[x]) == -1)
                this.selectedItem.variation.variations[x] = values[0];
        })

        this.setSelectedItem();
        this.setDistinctVariation();
    }

    setSelectedItem(){

        if(Object.keys(this.selectedItem.variation.variations).length != Object.keys(this.model.shopItemVariations[0].variation.variations).length)
            return;

        var selectedKeys = Object.keys(this.selectedItem.variation.variations); 
        var variations = this.model.shopItemVariations.map( x => {
            return x.variation
        });

        selectedKeys.forEach( x => {
            variations = variations.filter( y => {
                return y.variations[x] == this.selectedItem.variation.variations[x]
            })
        })

        var item = this.model.shopItemVariations.find( c =>  {
            return c.variation == variations[0];
        })

        this.selectedItem.identifier = item.identifier;
        this.selectedItem.availability = item.availability;
        this.selectedItem.variation.price = variations[0].price;
    }

    setDistinctVariation(){
        
        if(Object.keys(this.selectedItem.variation.variations).length == 0)
            this.selectedItem = JSON.parse(JSON.stringify(this.model.shopItemVariations[0]));


            
        var variations = this.model.shopItemVariations.map( element => {
            return element.variation.variations
        });

        var keys = Object.keys(this.selectedItem.variation.variations);
        this.distinctVariations = {};

        keys.forEach(key => {
            this.model.shopItemVariations.forEach(element => {
                if(this.distinctVariations[key] == null)
                    this.distinctVariations[key] = [];
                
                if(this.distinctVariations[key].indexOf(element.variation.variations[key]) == -1){
                    if(keys[0] == key)
                        this.distinctVariations[key].push(element.variation.variations[key])
                    else if(element.variation.variations[keys[0]] == this.selectedItem.variation.variations[keys[0]])
                        this.distinctVariations[key].push(element.variation.variations[key])
                }
            });
        });

        this.loaded = true;
    }

    minusAmount() {
        if(this.amountNumber > 1)
            this.amountNumber--;
    }


    //qui
    addToCart() {
        var postVM = new OM.AddToCartVm();
        postVM.communityIdentifier = this.communityIdentifier;
        postVM.itemIdentifier = this.shopItemIdentifier;
        postVM.variationItemIdentifier = this.selectedItem.identifier;
        postVM.amount = this.amountNumber;
        postVM.period = null;
        postVM.date = null;

        CartClient.addToCart(postVM)
        .then( x => {
            this.$router.push('/communities/' + this.$route.params.slug + '/shop');
        })
        .catch(x => {
            var message : string = x.message;
            var messageKey = "app_" + message.toLowerCase().replaceAll(" ", "_");
            this.errorMessage = LocalizationServices.getLocalizedValue(messageKey, message);
        })
    }

    openGallery() {
        this.$opModal.show(ImageGalleryModal, {
            title: this.model.title,
            gallery: this.model.gallery
        })
    }

}

