
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import SwiperSlides from '@/components/swiperSlides.vue';

@Options({
    components: {
        SwiperSlides
    }
})
export default class ImageGalleryModal extends Vue {

    @Prop() title: string;
    @Prop() gallery: string[];

    @Prop({
        default: null
    }) index: number;

    localIndex: number = 0;

    showSwiper: boolean = false;

    created() {
        if(this.index || this.index == 0)
            this.selectImage(this.index);
    }

    selectImage(index: number) {
        this.localIndex = index;
        this.showSwiper = true;
    }

}

